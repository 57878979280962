import axios from '@/common/axios'
import qs from 'qs'

export function addMaterialReport (data) {
  return axios({
    method: 'post',
    url: '/quality/materialReport/add',
    data: qs.stringify(data)
  })
}

export function deleteMaterialReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/materialReport/delete/' + id
  })
}

export function updateMaterialReport (data) {
  return axios({
    method: 'put',
    url: '/quality/materialReport/update',
    data: qs.stringify(data)
  })
}

export function selectMaterialReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/materialReport/info/' + id
  })
}

export function selectMaterialReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/materialReport/list',
    params: query
  })
}
