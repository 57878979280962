<template>
  <div id="materialReportInstructions">
    <el-dialog
      :title="materialReportInstructionsFormTitle"
      width="800px"
      :visible.sync="materialReportInstructionsDialogVisible"
      :close-on-click-modal="false"
      @close="materialReportInstructionsDialogClose"
    >
      <el-form
        ref="materialReportInstructionsFormRef"
        :model="materialReportInstructionsForm"
        :rules="materialReportInstructionsFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品(物料)名称" prop="materialName">
              <el-input v-model="materialReportInstructionsForm.materialName" placeholder="请输入产品(物料)名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产批号" prop="batchNum">
              <el-input v-model="materialReportInstructionsForm.batchNum" placeholder="请输入生产批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批量" prop="batchSize">
              <el-input v-model="materialReportInstructionsForm.batchSize" placeholder="请输入批量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="materialReportInstructionsForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请验日期" prop="checkDate">
              <el-date-picker v-model="materialReportInstructionsForm.checkDate" placeholder="请选择请验日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker v-model="materialReportInstructionsForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验依据" prop="basis">
              <el-input v-model="materialReportInstructionsForm.basis" placeholder="请输入检验依据" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="外观：外观整洁，无破损，无折痕，整批色泽均一，无明显色差，印刷清晰，位置准确" prop="appearance" label-width="600px">
              <el-radio-group v-model="materialReportInstructionsForm.appearance">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="材质：材质符合质量要求，与标准样版一致" prop="materialQuality" label-width="600px">
              <el-radio-group v-model="materialReportInstructionsForm.materialQuality">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="印刷：内容、排版及颜色与样板一致" prop="printing" label-width="600px">
              <el-radio-group v-model="materialReportInstructionsForm.printing">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="尺寸：尺寸大小符合要求，误差±1mm" prop="size" label-width="600px">
              <el-radio-group v-model="materialReportInstructionsForm.size">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验结论" prop="conclusion">
              <el-radio-group v-model="materialReportInstructionsForm.conclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="materialReportInstructionsForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告人" prop="reporter">
              <el-input v-model="materialReportInstructionsForm.reporter" placeholder="请输入报告人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="reporterDate">
              <el-date-picker v-model="materialReportInstructionsForm.reporterDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="materialReportInstructionsForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="reviewerDate">
              <el-date-picker v-model="materialReportInstructionsForm.reviewerDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="materialReportInstructionsForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="approverDate">
              <el-date-picker v-model="materialReportInstructionsForm.approverDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="materialReportInstructionsDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialReportInstructionsFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品(物料)名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入产品(物料)名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialReportInstructionsPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialName" label="产品(物料)名称" />
      <el-table-column prop="batchNum" label="生产批号" />
      <el-table-column prop="batchSize" label="批量" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column label="请验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkDate">{{ scope.row.checkDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="basis" label="检验依据" />
      <el-table-column label="外观">
        <template slot-scope="scope">
          <span v-if="scope.row.appearance === 0">不合格</span>
          <span v-if="scope.row.appearance === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="材质">
        <template slot-scope="scope">
          <span v-if="scope.row.materialQuality === 0">不合格</span>
          <span v-if="scope.row.materialQuality === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="印刷">
        <template slot-scope="scope">
          <span v-if="scope.row.printing === 0">不合格</span>
          <span v-if="scope.row.printing === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="尺寸">
        <template slot-scope="scope">
          <span v-if="scope.row.size === 0">不合格</span>
          <span v-if="scope.row.size === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="检验结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 0">不合格</span>
          <span v-if="scope.row.conclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="reporter" label="报告人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reporterDate">{{ scope.row.reporterDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approverDate">{{ scope.row.approverDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialReportInstructionsPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMaterialReport, deleteMaterialReport, updateMaterialReport, selectMaterialReportInfo, selectMaterialReportList } from '@/api/quality/materialReport'

export default {
  data () {
    return {
      materialReportInstructionsDialogVisible: false,
      materialReportInstructionsFormTitle: '',
      materialReportInstructionsForm: {
        id: '',
        materialName: '',
        batchNum: '',
        batchSize: '',
        spec: '',
        checkDate: '',
        reportDate: '',
        basis: '',
        appearance: '',
        materialQuality: '',
        printing: '',
        size: '',
        conclusion: '',
        remarks: '',
        reporter: '',
        reporterDate: '',
        reviewer: '',
        reviewerDate: '',
        approver: '',
        approverDate: '',
        type: 6
      },
      materialReportInstructionsFormRules: {
        materialName: [{ required: true, message: '产品(物料)名称不能为空', trigger: ['blur', 'change']}]
      },
      materialReportInstructionsPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: '',
        type: 6
      }
    }
  },
  created () {
    selectMaterialReportList(this.searchForm).then(res => {
      this.materialReportInstructionsPage = res
    })
  },
  methods: {
    materialReportInstructionsDialogClose () {
      this.$refs.materialReportInstructionsFormRef.resetFields()
    },
    materialReportInstructionsFormSubmit () {
      if (this.materialReportInstructionsFormTitle === '说明书原料检验报告书详情') {
        this.materialReportInstructionsDialogVisible = false
        return
      }
      this.$refs.materialReportInstructionsFormRef.validate(async valid => {
        if (valid) {
          if (this.materialReportInstructionsFormTitle === '新增说明书原料检验报告书') {
            await addMaterialReport(this.materialReportInstructionsForm)
          } else if (this.materialReportInstructionsFormTitle === '修改说明书原料检验报告书') {
            await updateMaterialReport(this.materialReportInstructionsForm)
          }
          this.materialReportInstructionsPage = await selectMaterialReportList(this.searchForm)
          this.materialReportInstructionsDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialReportInstructionsFormTitle = '新增说明书原料检验报告书'
      this.materialReportInstructionsDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialReport(row.id)
        if (this.materialReportInstructionsPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.materialReportInstructionsPage = await selectMaterialReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialReportInstructionsFormTitle = '修改说明书原料检验报告书'
      this.materialReportInstructionsDialogVisible = true
      this.selectMaterialReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialReportInstructionsFormTitle = '说明书原料检验报告书详情'
      this.materialReportInstructionsDialogVisible = true
      this.selectMaterialReportInfoById(row.id)
    },
    selectMaterialReportInfoById (id) {
      selectMaterialReportInfo(id).then(res => {
        this.materialReportInstructionsForm.id = res.id
        this.materialReportInstructionsForm.materialName = res.materialName
        this.materialReportInstructionsForm.batchNum = res.batchNum
        this.materialReportInstructionsForm.batchSize = res.batchSize
        this.materialReportInstructionsForm.spec = res.spec
        this.materialReportInstructionsForm.checkDate = res.checkDate
        this.materialReportInstructionsForm.reportDate = res.reportDate
        this.materialReportInstructionsForm.basis = res.basis
        this.materialReportInstructionsForm.appearance = res.appearance
        this.materialReportInstructionsForm.materialQuality = res.materialQuality
        this.materialReportInstructionsForm.printing = res.printing
        this.materialReportInstructionsForm.size = res.size
        this.materialReportInstructionsForm.conclusion = res.conclusion
        this.materialReportInstructionsForm.remarks = res.remarks
        this.materialReportInstructionsForm.reporter = res.reporter
        this.materialReportInstructionsForm.reporterDate = res.reporterDate
        this.materialReportInstructionsForm.reviewer = res.reviewer
        this.materialReportInstructionsForm.reviewerDate = res.reviewerDate
        this.materialReportInstructionsForm.approver = res.approver
        this.materialReportInstructionsForm.approverDate = res.approverDate
        this.materialReportInstructionsForm.type = res.type
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportInstructionsPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportInstructionsPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportInstructionsPage = res
      })
    }
  }
}
</script>

<style>
</style>
